import { Updatable } from '../../..';

export default class ProntoPagaSettings extends Updatable {
    public bearerToken: string;
    public secretKey: string;
    public testMode: boolean;

    constructor(settings?: Partial<ProntoPagaSettings>) {
        super();
        if (settings) this.update(settings);
    }

    update(entity: Partial<ProntoPagaSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}