import React, { useContext, useMemo } from "react";
import { AccelContext, Context } from '../AccelProvider/AccelProvider';
import type { NumberDelimeter } from '../../utils/converters/NumberConverter';
import { NumberConverter } from '../../utils/converters/NumberConverter';
import Humanize from 'humanize-plus';

type NumberFormatProps = {
    number: number;
    compact?: boolean;
    delimeter?: NumberDelimeter;
    precision?: number;
    className?: string;
    fractionalClassname?: string;
    truncateIfFractionalZero?: boolean;
    suffix?: React.ReactNode;
}

const NumberFormat: React.FC<NumberFormatProps> = (props) => {
    const { lang } = useContext(Context);

    const number = useMemo(() => props.delimeter
        ? NumberConverter.format(props.number ?? 0, props.delimeter, props.precision, false)
        : NumberConverter.locFormat(props.number ?? 0, lang, props.precision, false),
        [props.delimeter, props.number, props.precision]);

    const parts = useMemo(() => number.split('.'), [number]);
    const intPart = useMemo(() => {
        if (props.compact === true) {
            return Humanize.compactInteger(props.number, props.precision);
        }
        return parts[0];
    }, [parts, props.compact, props.precision]);

    const fractPart = useMemo(() => {
        // just in case
        const fract = parts.length == 1 ? '0' : parts[1];
        if (props.truncateIfFractionalZero == true && parseInt(fract) == 0)
            return null;
        return fract;
    }, [parts, props.truncateIfFractionalZero]);

    return <span className={props.className}>
        {intPart}
        {fractPart != null && <span className={props.fractionalClassname}>.{fractPart}</span>}
        {props.suffix}
    </span>;
}
NumberFormat.defaultProps = {
    truncateIfFractionalZero: false,
}
export default NumberFormat;