import { useCallback, useEffect, useRef } from 'react';

const isElementPartiallyInViewport = <T extends HTMLElement>(el: T | null) => {
    if (!el) return false;
    const rect = el.getBoundingClientRect();
    return (
        rect.top < (window.innerHeight || document.documentElement.clientHeight) &&
        rect.bottom > 0 &&
        rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
        rect.right > 0
    );
}

type AutoScrollerOptions = {
    enabled: boolean;
};

export const useAutoScroller = <T extends HTMLElement>(options: AutoScrollerOptions) => {
    const elementRef = useRef<T | null>(null);

    const scrollIntoView = useCallback(() => {
        elementRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, []);

    useEffect(() => {
        let isElVisible = isElementPartiallyInViewport(elementRef.current);
        if (options.enabled && !isElVisible)
            setTimeout(() => {
                scrollIntoView();
            }, 300);

    }, [options.enabled]);

    return [elementRef];
};