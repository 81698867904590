import { AccelFile, Entity, Scenario, SchoolApplication, TelegramGroupUser } from '../..';
import { observable, action } from 'mobx';
import moment, { type Moment } from 'moment';

export type TelegramGroupStatus = 'connecting' | 'connected';

export default class TelegramGroup extends Entity {
    constructor(g?: Partial<TelegramGroup>) {
        super(g);
        if (g) this.update(g);
    }

    externalId: string;
    externalName: string;
    owner: TelegramGroupUser;

    @observable name: string;
    @observable keyPhrase: string;
    @observable status: TelegramGroupStatus;
    @observable createdDate: Moment;
    @observable updatedDate: Moment | null;
    /** instructions html template */
    @observable description: string;

    @observable schoolApp: SchoolApplication;
    @observable image: AccelFile | null;
    @observable excludeNonExistingUsers: boolean;

    @observable scenario?: Scenario;
    @observable usersCount: number;
    @observable requestedUsersCount: number;

    @action update(g: Partial<TelegramGroup>, allowUndefined = false) {
        super.update(g, allowUndefined);
    }

    static fromJson(json: any): TelegramGroup {
        return new TelegramGroup({
            id: json.id,
            name: json.name,
            status: json.status,
            keyPhrase: json.keyPhrase,
            externalId: json.externalId,
            externalName: json.externalName,
            owner: json.owner ? TelegramGroupUser.fromJson(json.owner) : undefined,
            description: json.description,
            excludeNonExistingUsers: json.excludeNonExistingUsers,
            softDeleted: json.softDeleted,
            softDeletedDate: json.softDeletedDate ? moment(json.softDeletedDate) : undefined,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : null,
            schoolApp: json.schoolLink ? SchoolApplication.fromJson(json.schoolLink) : undefined,
            scenario: json.scenario ? Scenario.fromJson(json.scenario) : undefined,
            image: json.image ? AccelFile.fromJson(json.image) : null,
            usersCount: json.usersCount ?? 0,
            requestedUsersCount: json.requestedUsersCount ?? 0,
        });
    }
}