export { }

declare global {
    interface String {
        trimSpaces(removeInnerSpaces?: boolean): string;
        isValidEmail(): boolean;
        isValidUrl(): boolean;

        /**
        * @returns string with first letter capitalized
        */
        capitalize(): string;
    }
}

if (!String.prototype.trimSpaces) {
    String.prototype.trimSpaces = function (removeInnerSpaces: boolean = true): string {
        if (!removeInnerSpaces) return this.trim();
        return this.trim().replace(' ', '');
    }
}


if (!String.prototype.isValidEmail) {
    String.prototype.isValidEmail = function (): boolean {
        const match = this.match(/^[_a-zA-Z0-9][a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-.]{0,61}[a-zA-Z0-9])?)$/);
        return match != null;
    }
}

if (!String.prototype.isValidUrl) {
    String.prototype.isValidUrl = function (): boolean {
        try {
            new URL(this?.toString());
            return true;
        } catch (e) {
            return false;
        }
    }
}

if (!String.prototype.padStart) {
    String.prototype.padStart = function padStart(targetLength, padString) {
        targetLength = targetLength >> 0; //floor if number or convert non-number to 0;
        padString = String(padString || " ");
        if (this.length > targetLength) {
            return String(this);
        } else {
            targetLength = targetLength - this.length;
            if (targetLength > padString.length) {
                padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
            }
            return padString.slice(0, targetLength) + String(this);
        }
    };
}

if (!String.prototype.capitalize) {
    String.prototype.capitalize = function (): string {
        return this.charAt(0).toUpperCase() + this.slice(1);
    }
};