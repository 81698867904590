import { ApiRequest, ApiResponse, HttpMethod } from '../../..';
import { Lang } from '../../../../enums';

export type LocalizationWords = { key: string; value: string; }[];

export class FetchLocalizationRequest extends ApiRequest {
	public method = HttpMethod.GET;
	public url: string = '/localization';
	public body = {
		lang: this.lang,
		timestamp: this.timestamp
	};

	constructor(public lang: string, public timestamp?: number) {
		super();
	}
}

export class FetchLocalizationResponse extends ApiResponse {
	public words: LocalizationWords;
	public timestamp: number;
	public excludedLangs: Lang[];
	public needSync: boolean;

	constructor(response: any) {
		super(response);
		this.words = this.body.words
			? this.body.words
			: [];
		this.timestamp = this.body.timestamp;
		this.needSync = this.body.needSync;
		this.excludedLangs = this.body.excludedLangs ?? [];
	}
}
