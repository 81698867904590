import { isDev, StringCase } from '../../enums';
import LocalizationStore from '../../stores/LocalizationStore';
import { inject } from "react-ioc";

// TODO remove
export { StringCase }

export type PluralKeys = {
    one: string,
    two: string,
    other: string
}

export declare type LocStringCase = 'lower' | 'upper' | 'sentence';

type BaseLocalizationOptions = {
    case?: LocStringCase;
    args?: (string | number)[];
}

export type LocalizationOptions = BaseLocalizationOptions & {
    default?: string;
}

export type LocalizationPluralOptions = BaseLocalizationOptions & {
    default?: PluralKeys;
}

export default class Localization {
    missingWords: Map<string, string>;

    constructor(private locStore: LocalizationStore) {
        this.locStore = locStore || inject(this, LocalizationStore);

        if (isDev) {
            this.missingWords = new Map();
        }
    }

    get lang() {
        return this.locStore.lang;
    }

    get langs() {
        return this.locStore.langs;
    }

    word(key: string, options: LocalizationOptions = {}) {
        return this.getLoc(key, options, () => options.default);
    }

    wordPlural(number: number, keys: PluralKeys, options: LocalizationPluralOptions = {}) {
        if (number === undefined || !keys) {
            console.warn('[Localization] invalid args', number, keys);
            return '';
        }
        const key = this.getEnding(number, keys);
        return this.getLoc(key, options,
            () => options.default
                ? this.getEnding(number, options.default)
                : undefined);
    }

    hasWord(key: string) {
        key = key.trim();
        let res: string | null = this.locStore.words[key];
        return res != null;
    }

    private getLoc(key: string, options: BaseLocalizationOptions, getDefault: () => string | undefined) {
        key = key.trim();
        let res: string | null = this.locStore.words[key];
        if (res == null) {

            const def = getDefault();
            if (isDev) {
                if (!key.startsWith('Timezone.')) {
                    this.missingWords.set(key, def ?? '');
                    console.warn(`[Localization]`, key, def);
                }
            }

            if (def == null) return `::${key}::`;
            res = def;
        }
        if (options.args != null)
            res = this.setArgs(res, options.args);
        if (options.case != null)
            res = this.getCase(res, options.case);
        return res;
    }

    private setArgs(res: string, args: (string | number)[]) {
        for (let index = 0; index < args.length; index++)
            res = res.replace(`{${index}}`, args[index].toString());
        return res;
    }

    private getCase(res: string, stringCase: LocStringCase) {
        switch (stringCase) {
            case StringCase.lower:
                return res.toLowerCase();
            case StringCase.upper:
                return res.toUpperCase();
            default:
                return res;
        }
    }

    private getEnding(number: number, keys: PluralKeys) {
        number = Math.abs(number);
        const numArray = [2, 0, 1, 1, 1, 2];
        const index = number % 100 <= 4 || number % 100 >= 20 ? numArray[number % 10 < 5 ? number % 10 : 5] : 2;
        switch (index) {
            case 0: return keys.one;
            case 1: return keys.two;
            case 2: return keys.other;
            default: {
                console.error('Invalid pluralization number');
                // default
                return keys.other;
            }
        }
    }
}