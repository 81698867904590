import { observable, action } from 'mobx';
import { Lang } from '../../enums';
import { Designable } from '../Entity';
import AccelFile from '../file/accel-file';

export default class Document extends Designable {
    constructor(doc?: Partial<Document>) {
        super(doc);
        if (doc) this.update(doc);
    }

    @observable title: string;
    @observable url: string;
    @observable language: Lang;
    @observable file: AccelFile | null;

    @observable createdDate: Date;
    @observable updatedDate?: Date;

    @observable isPublished: boolean;

    /**
     * complete url to download attached file is exists
     */
    fileUrl: string;

    static fromJson(json: any): Document {
        return new Document({
            ...json,
            file: json.file ? AccelFile.fromJson(json.file) : null,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
        });
    }

    @action
    update(doc: Partial<Document>) {
        super.update(doc);
    }
}

