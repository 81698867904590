import { observable, action, toJS } from 'mobx';
import { Entity, AccelFile, VideoStream, VideoAccess, Updatable } from '..';
import { Lang } from '../../enums';
import { ISerializable } from '../Entity';

export type VideoBreadcrumbItem = {
    id: string;
    parentId: string;
    name: string;
    softDeleted: boolean;
}

export type WatermarkSettings = {
    enabled: boolean;
    frequencyDelayInSec: number;
}

/**
 * Video settings
 */
export class VideoSettings extends Updatable implements ISerializable {

    constructor(settings?: Partial<VideoSettings>) {
        super();
        if (settings) this.update(settings);
    }


    @observable watermark: WatermarkSettings;
    @action updateWatermark(watermark: Partial<WatermarkSettings>) {
        this.watermark = { ...this.watermark, ...watermark };
    }

    @action update(changes: Partial<VideoSettings>, allowUndefined = false) {
        super.update(changes, allowUndefined);
    }

    toJson() {
        return toJS({
            watermark: this.watermark
        });
    }

    clone(): VideoSettings {
        return new VideoSettings({
            ...this,
        });
    }

    hasChanges(settings: VideoSettings): boolean {
        return this.watermark?.enabled != settings.watermark?.enabled
            || this.watermark?.frequencyDelayInSec != settings.watermark?.frequencyDelayInSec;
    }

    static default(): VideoSettings {
        return new VideoSettings({
            watermark: { enabled: false, frequencyDelayInSec: 30 }
        });
    }

    static fromJson(json: any): VideoSettings {
        const def = VideoSettings.default();
        return new VideoSettings({
            watermark: json.watermark || def.watermark
        });
    }
}
export default class Video extends Entity {
    constructor(video?: Partial<Video>) {
        super(video);
        if (video) this.update(video);
    }

    @observable name: string;
    @observable shortDescription: string;
    @observable lang: Lang;
    @observable viewsCount: number;

    @observable createdDate: Date;
    @observable updatedDate: Date;
    @observable isNotCompletedStreamExist: boolean;

    @observable currentVideoStream: VideoStream;
    @observable primaryImage: AccelFile;
    @observable primaryImageFileId: string;

    @observable accessAnywhere: boolean;
    @observable access: VideoAccess | null;

    @observable breadcrumbs: VideoBreadcrumbItem[];
    @observable settings: VideoSettings;

    @action
    update(changes: Partial<Video>, allowUndefined = false) {
        super.update(changes, allowUndefined);
    }

    clone(): Video {
        return new Video({
            ...this,
            currentVideoStream: this.currentVideoStream?.clone(),
            primaryImage: this.primaryImage?.clone(),
            settings: this.settings?.clone()
        });
    }

    hasChanges(video: Video) {
        return this.name !== video.name
            || this.shortDescription !== video.shortDescription
            || this.lang !== video.lang
            || this.settings.hasChanges(video.settings);
    }


    static fromJson(json: any): Video {
        return new Video({
            ...json,
            createdDate: json.createdDate ? new Date(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? new Date(json.updatedDate) : undefined,
            currentVideoStream: json.currentVideoStream ? VideoStream.fromJson(json.currentVideoStream) : undefined,
            primaryImage: json.primaryImage?.file ? AccelFile.fromJson(json.primaryImage.file) : undefined,
            primaryImageFileId: json.primaryImage?.file?.id ? json.primaryImage.file.id : undefined,
            access: json.videoAccess ? VideoAccess.fromJson(json.videoAccess) : null,
            breadcrumbs: json.path,
            settings: json.settingsJson
                ? VideoSettings.fromJson(JSON.parse(json.settingsJson))
                : VideoSettings.default()
        });
    }
}

