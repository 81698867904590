import { isEmpty } from "../../../utils";
import { ApplicationSettings } from "./ApplicationSettings";

export default class GoogleAnalyticsApplicationSettings extends ApplicationSettings {
    constructor(s?: Partial<GoogleAnalyticsApplicationSettings>) {
        super();
        if (s) this.update(s);
    }

    public name: string;
    public accessToken: string;
    public measurementId: string;
    public siteIds: string[];
    public isAccessTokenEncrypted: boolean;

    get isValid() {
        return !isEmpty(this.accessToken) && !isEmpty(this.measurementId);
    }

    update(entity: Partial<GoogleAnalyticsApplicationSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }

    static fromJson(json: any): GoogleAnalyticsApplicationSettings {
        return new GoogleAnalyticsApplicationSettings({ ...json });
    }
}