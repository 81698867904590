import { observable, action } from 'mobx';
import moment from 'moment';
import { type Moment } from 'moment';
import { CoursePlan, Course, AccelFile } from '../../'
import type { TimeMeasure } from '../..';
import { Designable } from '../../Entity';
import { DayOfWeek } from '../../../enums';

export enum CourseItemAvailability {
    RegardlessOfPreviousSteps = 'regardlessOfPreviousSteps',
    PreviousStepCompleted = 'previousStepCompleted',
    AllPreviousStepsCompleted = 'allPreviousStepsCompleted'
}
export enum RegularTimeDelayCondition {
    PreviousStep = 'previousStep',
    CourseStart = 'courseStart',
    AccessStart = 'accessStart',
    PreviousSectionStarted = 'previousSectionStarted',
    PreviousSectionCompleted = 'previousSectionCompleted'
}
export enum FlowTimeDelayCondition {
    PreviousStep = 'previousStep',
    FlowStart = 'flowStart',
    CourseStart = 'courseStart',
    PreviousSectionStarted = 'previousSectionStarted',
    PreviousSectionCompleted = 'previousSectionCompleted'
}
export enum CourseItemType {
    Step = 'step',
    Section = 'section',
}
export default class CourseItem extends Designable {
    constructor(item?: Partial<CourseItem>) {
        super(item);
        if (item) this.update(item);
    }

    @observable title: string;
    @observable position: number;
    @observable isDraft: boolean;
    @observable isOptional: boolean;
    @observable type: CourseItemType;
    @observable comment: string;

    // restrictions and availability
    @observable allowComments: boolean;
    @observable showComments: boolean;
    @observable disableCommentModeration: boolean;
    @observable ratingEnabled: boolean;
    @observable ratingRequired: boolean;
    @observable ratingCommentRequired: boolean;
    @observable availableOnAllPlans: boolean;
    @observable showRegardlessPlan: boolean;
    @observable availability: CourseItemAvailability;

    @observable regularTimeDelayActive: boolean;
    @observable regularTimeDelay: number;
    @observable regularTimeDelayCondition: RegularTimeDelayCondition;
    @observable regularTimeDelayMeasureType: TimeMeasure;
    @observable regularWaitUntilTimeDaysOfWeekActive: boolean;
    @observable regularWaitUntilDaysOfWeek: DayOfWeek[];
    @observable regularWaitUntilTimeActive: boolean;
    @observable regularWaitUntilTime?: Moment | null;

    @observable flowTimeDelayActive: boolean;
    @observable flowTimeDelay: number;
    @observable flowTimeDelayCondition: FlowTimeDelayCondition;
    @observable flowTimeDelayMeasureType: TimeMeasure;
    @observable flowWaitUntilTimeDaysOfWeekActive: boolean;
    @observable flowWaitUntilDaysOfWeek: DayOfWeek[];
    @observable flowWaitUntilTimeActive: boolean;
    @observable flowWaitUntilTime?: Moment | null;

    @observable dateDelayActive: boolean;
    @observable dateDelay?: Moment | null;

    @observable preventCopingAndSelectingText: boolean;

    @observable blockCourseUntilTasksApproved: boolean;
    @observable theoryVisible: boolean;
    @observable tasksVisible: boolean;
    @observable testsVisible: boolean;

    @observable score: number;

    @observable plans: Array<CoursePlan>;
    @observable files: AccelFile[];

    @observable parent?: CourseItem;
    @observable parentItemId?: string | null;
    @observable children: CourseItem[];

    @observable course: Course;

    @observable createdDate: moment.Moment;
    @observable updatedDate?: moment.Moment;

    static fromJson(json: any): CourseItem {
        return new CourseItem({
            ...json,
            regularTimeDelay: json.regularTimeDelay ? json.regularTimeDelay : 1,
            regularTimeDelayCondition: json.regularTimeDelayCondition ? json.regularTimeDelayCondition : RegularTimeDelayCondition.PreviousStep,
            regularTimeDelayMeasureType: json.regularTimeDelayMeasureType ? json.regularTimeDelayMeasureType : 'days',
            flowTimeDelay: json.flowTimeDelay ? json.flowTimeDelay : 1,
            flowTimeDelayCondition: json.flowTimeDelayCondition ? json.flowTimeDelayCondition : FlowTimeDelayCondition.PreviousStep,
            flowTimeDelayMeasureType: json.flowTimeDelayMeasureType ? json.flowTimeDelayMeasureType : 'days',
            plans: json.plans ? json.plans.map((x: any) => new CoursePlan(x.coursePlan)) : undefined,
            parent: json.parentItem ? CourseItem.fromJson(json.parentItem) : undefined,
            children: json.children ? json.children.map(CourseItem.fromJson) : undefined,
            course: json.course ? Course.fromJson(json.course) : undefined,
            files: json.fileLinks ? json.fileLinks.map((x: any) => AccelFile.fromJson(x.file)) : [],
            dateDelay: json.dateDelay ? moment(json.dateDelay) : undefined,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            regularWaitUntilTime: json.regularWaitUntilTime ? moment(json.regularWaitUntilTime) : undefined,
            flowWaitUntilTime: json.flowWaitUntilTime ? moment(json.flowWaitUntilTime) : undefined,
        });
    }

    @action update(item: Partial<CourseItem>) {
        super.update(item);
    }
}