import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import styles from './TextHighlighter.module.scss';

type TextHighlighterProps = {
    textToHighlight: string;
    searchWords: string[];
    highlightClassName?: string;
    highlightStyle?: React.CSSProperties;
    unhighlightClassName?: string;
    unhighlightStyle?: React.CSSProperties;
};

const TextHighlighter: React.FC<TextHighlighterProps> = ({
    textToHighlight,
    searchWords,
    highlightClassName,
    highlightStyle,
    unhighlightClassName,
    unhighlightStyle,
}) => {

    const getHighlightedText = useCallback((text: string, words: string[]): React.ReactNode => {
        const regex = new RegExp(`(${words.join('|')})`, 'gi');
        const parts = text?.split(regex) ?? [];

        return parts.filter(Boolean).map((part, index) => {
            if (words.some(word => new RegExp(word, 'gi').test(part)))
                return <span key={index} className={highlightClassName} style={highlightStyle}>
                    {part}
                </span>;
            else
                return <span key={index} className={unhighlightClassName} style={unhighlightStyle}>
                    {part}
                </span>;
        });
    }, [highlightClassName, highlightStyle, unhighlightClassName, unhighlightStyle]);

    if (searchWords.length === 0) {
        return <>{textToHighlight}</>;
    }

    return <>{getHighlightedText(textToHighlight, searchWords)}</>;
};

TextHighlighter.defaultProps = {
    highlightClassName: styles.highlighted,
    unhighlightClassName: styles.unhighlighted,
};

export default observer(TextHighlighter);
