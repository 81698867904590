import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip, TooltipProps } from 'antd';
import React from 'react';

type Props = {
    icon?: React.ReactNode;
    label?: React.ReactNode;
    className?: string;
    iconClassName?: string;
    tooltip?: boolean;
    tooltipProps?: TooltipProps;
}
const HelpText: React.FC<Props> = ({ className, icon, label, iconClassName, tooltip, tooltipProps }) => {

    if (tooltip === true)
        return <Tooltip title={label} {...tooltipProps}>
            <span className={className}>
                <span className={iconClassName}>{icon}</span>
            </span>
        </Tooltip>;
    return <span className={className}>
        {icon && <span className={iconClassName}>{icon}&nbsp;</span>}
        {label}
    </span>;
}
HelpText.defaultProps = {
    icon: <InfoCircleOutlined />,
    className: 'fs-12',
    iconClassName: 'color-primary'
}
export default HelpText;