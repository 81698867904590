import { observable, action } from 'mobx';
import { Entity } from '..';

// TODO move to school project
export default class SchoolSettings extends Entity {
    constructor(settings?: Partial<SchoolSettings>) {
        super(settings);
        if (settings) this.update(settings);
    }

    @observable registrationAgreementText: string | null
    @observable isRegistrationAgreementEnabled: boolean;
    @observable footerHtml: string | null;
    @observable isPageFooterEnabled: boolean;
    @observable isLearnPageFooterEnabled: boolean;
    @observable userSessionCountLimit: number;
    @observable userSessionCountLimitEnabled: boolean;

    @action update(settings: Partial<SchoolSettings>, allowUndefined = false) {
        super.update(settings, allowUndefined);
    }

    copy() {
        return new SchoolSettings({
            id: this.id,
            registrationAgreementText: this.registrationAgreementText,
            isRegistrationAgreementEnabled: this.isRegistrationAgreementEnabled,
            footerHtml: this.footerHtml,
            isPageFooterEnabled: this.isPageFooterEnabled,
            isLearnPageFooterEnabled: this.isLearnPageFooterEnabled,
            userSessionCountLimit: this.userSessionCountLimit,
            userSessionCountLimitEnabled: this.userSessionCountLimitEnabled
        });
    }

    static fromJson(json: any) {
        return new SchoolSettings({
            ...json,
            footerHtml: json.footerText,
        });
    }
}