import React, { useContext, useEffect, useState } from "react";
import 'react-phone-number-input/style.css';
import PhoneInputBase, { formatPhoneNumber, isValidPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import styles from './PhoneInput.module.scss';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { combineClasses } from '../../utils';
import { AccelContext, Context } from '../AccelProvider/AccelProvider';
import { LoadingOverlay } from '..';

export { formatPhoneNumber, isValidPhoneNumber, formatPhoneNumberIntl };

type CustomPhoneInputProps = {
    size?: SizeType;
    borderless?: boolean;
    disabled?: boolean;
    placeholder?: string;
    value?: string;
    onChange?: (phone: string) => void;
    onBlur?: () => void;
    className?: string;
    autoFetchCountry?: boolean;
}
/**
 * @deprecated Use PhoneInput2 instead
 */
const PhoneInput: React.FC<CustomPhoneInputProps> = ({ borderless, autoFetchCountry, disabled, onChange, onBlur, placeholder, size, value, className }) => {
    let ctx = useContext(Context);

    const locator = ctx.geoLocator;
    const [country, setCountry] = useState<string>();

    useEffect(() => {
        (async () => {
            const countryString = await locator.fetchCounty();
            setCountry(countryString.toUpperCase());
        })();
    }, []);

    if (autoFetchCountry && !country)
        return <LoadingOverlay size='small' />;
    return <div className={combineClasses(styles.phone_input, className)} data-size={size ?? 'middle'} data-borderless={borderless ?? false}>
        <PhoneInputBase value={value ?? ''} onChange={(x) => onChange?.(x as string)} onBlur={() => onBlur?.()} disabled={disabled} placeholder={placeholder} defaultCountry={country} />
    </div>;
}
PhoneInput.defaultProps = {
    autoFetchCountry: true
}
export default PhoneInput;