
import { observable, action, computed } from 'mobx';
import { Entity, CoursePlan, FlowDate, Course, Contact, Library, PurchaseOrder, Product, Flow, BaseFilter } from '../..';
import moment, { type Moment } from 'moment';
import { ISerializable } from '../../Entity';
import { TimeSpan } from '../../../utils';


export declare type ContactLicenseType = 'flowDate' | 'course' | 'library' | 'product' | 'allCourses' | 'allLibraries';
export declare type ContactLicenseState = 'active' | 'paused';

export type LicenceTimeLeft = {
    days: number;
    hours: number;
    lessThanOneDay: boolean;
    lessThanOneHour: boolean;
    daysAgo: number;
}

export default class ContactLicense extends Entity {
    constructor(license?: Partial<ContactLicense>) {
        super(license);
        if (license) this.update(license);
    }

    @observable contact: Contact;
    @observable purchaseOrder?: PurchaseOrder;

    @observable course: Course | null;
    @observable coursePlan: CoursePlan | null;
    @observable flowDate: FlowDate | null;
    @observable library: Library | null;
    @observable libraryItemIds: string[] | null;
    @observable product: Product | null;
    @observable type: ContactLicenseType;
    @observable state: ContactLicenseState;

    @observable beginDate: moment.Moment;
    @observable endDate: moment.Moment | null;
    @observable creditEndDate: moment.Moment | null;
    @observable currentSuspension: ContactLicenseSuspension | null;
    @observable suspendedPeriod: TimeSpan;
    @observable renewalCountLimitEnabled: boolean = false;
    @observable renewalCountLimit: number | null = null;

    @computed get isActive() {
        if (this.endDate == null)
            return this.beginDate.isSameOrBefore(moment());
        return this.beginDate.isSameOrBefore() && this.endDate.isAfter();
    }

    @computed get isSuspended() {
        return this.currentSuspension != null;
    }

    @computed get isBeginInFuture() {
        return this.beginDate.isAfter(moment());
    }

    //#region Subscription

    @computed get isSubscriptionActive() {
        return this.beginDate.isSameOrBefore() && (
            (this.creditEndDate && this.creditEndDate.isAfter()) ||
            (!this.creditEndDate && this.endDate && this.endDate.isAfter()) ||
            (this.endDate == null)
        );
    }

    @computed get isSubscriptionEnding() {
        return this.isSubscriptionActive && this.endDate && this.creditEndDate && this.endDate.isSameOrBefore() && this.creditEndDate.isAfter();
    }

    @computed get isSubscriptionEnded() {
        return !this.isSubscriptionActive && this.endDate && this.creditEndDate && this.creditEndDate.isSameOrBefore();
    }


    @computed get canPauseSubscription() {
        return this.state == 'active' && !this.isSubscriptionEnded;
    }

    @computed get canContinueSubscription() {
        return this.state == 'paused' && !this.isSubscriptionEnded;
    }

    @computed get hasLibraryItems() {
        return this.libraryItemIds && this.libraryItemIds.length > 0;
    }

    //#endregion

    @computed get periodInDays() {
        if (this.beginDate != null && this.endDate != null)
            return this.endDate.diff(this.beginDate, 'days');
        return 0;
    }

    get daysAndHoursLeft() {
        return this.calculateDaysLeft(this.endDate, false);
    }

    get creditDaysAndHoursLeft() {
        return this.calculateDaysLeft(this.creditEndDate, true);
    }

    get subjectId() {
        switch (this.type) {
            case 'flowDate':
                return this.flowDate?.id;
            case 'course':
                return this.course?.id;
            case 'library':
                return this.library?.id;
            case 'product':
                return this.product?.id;
        }
    }

    get isDeleted() {
        switch (this.type) {
            case 'flowDate':
                return this.flowDate?.softDeleted || this.flowDate?.flow.softDeleted;
            case 'course':
                return this.coursePlan?.softDeleted || this.course?.softDeleted;
            case 'library':
                return this.library?.softDeleted;
            case 'product':
                return this.product?.softDeleted;
        }
    }

    get canEditContent() {
        return this.purchaseOrder == null;
    }


    changeType(type: ContactLicenseType) {
        this.update({
            type: type,
            course: null,
            coursePlan: null,
            flowDate: null,
            product: null,
            library: null,
            libraryItemIds: null,
            renewalCountLimitEnabled: false,
            renewalCountLimit: null
        });
    }

    isDateInLicense(date: moment.Moment, granularity?: moment.unitOfTime.StartOf) {
        return this.beginDate.isSameOrBefore(date, granularity) && (!this.endDate || this.endDate.isSameOrAfter(date, granularity));
    }

    private calculateDaysLeft(date: Moment | null, isCredit: boolean): LicenceTimeLeft {
        if (date == null)
            return { days: isCredit ? 0 : Infinity, hours: 0, lessThanOneDay: false, lessThanOneHour: false, daysAgo: 0 };


        let totalHoursLeft = date.diff(moment.now(), 'hours', true);
        let daysLeft = Math.trunc(totalHoursLeft / 24);
        let hoursLeft = Math.floor(totalHoursLeft % 24);

        if (totalHoursLeft < 0)
            return { days: 0, hours: 0, daysAgo: Math.abs(daysLeft), lessThanOneDay: false, lessThanOneHour: false };

        const lessThanOneDay = daysLeft < 1;
        const lessThanOneHour = daysLeft === 0 && totalHoursLeft < 1;

        return { days: daysLeft, hours: hoursLeft, lessThanOneDay, lessThanOneHour, daysAgo: 0 };
    }

    static fromJson(json: any): ContactLicense {
        const license = new ContactLicense({
            ...json,
            contact: json.contact ? Contact.fromJson(json.contact) : undefined,
            beginDate: json.beginDate ? moment(json.beginDate) : undefined,
            endDate: json.endDate ? moment(json.endDate) : null,
            creditEndDate: json.creditEndDate ? moment(json.creditEndDate) : null,
            course: json.course ? Course.fromJson(json.course) : undefined,
            coursePlan: json.coursePlan ? CoursePlan.fromJson(json.coursePlan) : undefined,
            product: json.product ? new Product(json.product) : undefined,
            flowDate: json.flowDate ? FlowDate.fromJson(json.flowDate) : undefined,
            library: json.libraryAccess ? Library.fromJson(json.libraryAccess.library) : undefined,
            libraryItemIds: (json.libraryAccess && json.libraryAccess.itemAccesses)
                ? json.libraryAccess.itemAccesses.map((x: any) => x.libraryItem.id)
                : null,
            purchaseOrder: json.purchaseOrder ? PurchaseOrder.fromJson(json.purchaseOrder) : undefined,
            currentSuspension: json.currentSuspension ? ContactLicenseSuspension.fromJson(json.currentSuspension) : null,
            suspendedPeriod: json.suspendedPeriodInSec ? TimeSpan.fromSeconds(json.suspendedPeriodInSec) : TimeSpan.zero,
        });

        if (license.coursePlan)
            license.update({ course: license.coursePlan?.course });

        return license;
    }

    @action
    update(license: Partial<ContactLicense>) {
        super.update(license);
    }
}

export class ContactLicenseSuspension extends Entity implements ISerializable {

    constructor(s?: Partial<ContactLicenseSuspension>) {
        super();
        if (s) this.update(s);
    }

    createdDate: moment.Moment;
    updatedDate: moment.Moment;
    @observable beginDate: moment.Moment;
    @observable endDate: moment.Moment;
    @observable license: ContactLicense;

    @computed get periodInDays() {
        if (this.beginDate != null && this.endDate != null)
            return this.endDate.diff(this.beginDate, 'days');
        return 0;
    }

    @computed get isActive() {
        return this.beginDate.isSameOrBefore() && this.endDate.isAfter();
    }

    toJson() {
        return {
            beginDate: this.beginDate,
            endDate: this.endDate,
            licenseId: this.license?.id,
        };
    }

    @action update(s: Partial<ContactLicenseSuspension>) {
        super.update(s);
    }

    static fromJson(json: any): ContactLicenseSuspension {
        return new ContactLicenseSuspension({
            ...json,
            createdDate: json.createdDate ? moment(json.createdDate) : undefined,
            updatedDate: json.updatedDate ? moment(json.updatedDate) : undefined,
            beginDate: json.beginDate ? moment(json.beginDate) : undefined,
            endDate: json.endDate ? moment(json.endDate) : null,
            license: json.license ? ContactLicense.fromJson(json.license) : undefined,
        });
    }
}

export class ContactLicenseSuspensionFilter extends BaseFilter<ContactLicenseSuspension> {
    constructor(filter?: Partial<ContactLicenseSuspensionFilter>) {
        super();
        if (filter) this.update(filter);
    }

    licenseIds: string[];

    update(changes: Partial<ContactLicenseSuspensionFilter>) {
        super.update(changes);
    }
}