import { observable, action, computed, runInAction, toJS } from 'mobx';
import Entity, { ISerializable } from '../Entity';
import { isEmpty } from '../../utils';

export type CustomFieldValueListOption = {
    id: string;
    value: string;
}

export type FilterCustomField = {
    id: string;
    condition: string;
    value?: any;
    type: CustomFieldType;
}

export enum CustomFieldType {
    Text = 'text',
    TextArea = 'textArea',
    DateTime = 'dateTime',
    Number = 'number',
    Boolean = 'boolean',
    List = 'list',
    MultiList = 'multiList',
    Date = 'date',
}

export default class CustomField extends Entity implements ISerializable {
    constructor(f?: Partial<CustomField>) {
        super();
        if (f) this.update(f);
    }


    @observable name: string;
    @observable keyName: string;
    @observable additionalData: string;
    @observable type: CustomFieldType;
    @observable position: number;
    @observable visible: boolean;
    @observable required: boolean;

    @computed get hasKey() {
        return !isEmpty(this.keyName);
    }

    @computed get isListType() {
        return this.type == CustomFieldType.List || this.type == CustomFieldType.MultiList;
    }

    @computed get listOptions(): CustomFieldValueListOption[] {
        switch (this.type) {
            case CustomFieldType.List:
            case CustomFieldType.MultiList:
                return (this.additionalData ? JSON.parse(this.additionalData) : []) as CustomFieldValueListOption[];
            default:
                return [];
        }
    }

    @computed get isDateType () {
        return this.type == CustomFieldType.Date || this.type == CustomFieldType.DateTime;
    }

    set listOptions(options: CustomFieldValueListOption[]) {
        runInAction(() => this.additionalData = JSON.stringify(options));
    }

    clone(f?: Partial<CustomField>): CustomField {
        return new CustomField({
            ...this,
            ...f
        })
    }

    toJson() {
        return toJS({
            name: this.name,
            keyName: this.keyName,
            additionalData: this.additionalData,
            type: this.type,
            required: this.required,
            visible: this.visible,
        });
    }

    @action update(attr: Partial<CustomField>, allowUndefined = false) {
        super.update(attr, allowUndefined);
    }

    static default() {
        return new CustomField({
            type: CustomFieldType.Text,
            additionalData: JSON.stringify([])
        });
    }

    static fromJson(json: any) {
        return new CustomField({
            ...json
        });
    }
}