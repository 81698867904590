import React from 'react';
import { DisposableStack, TimeSpan } from '../../utils';

export type AccelPlayerController = {
    mute(): void;
    unmute(): void;
    play(): void;
    pause(): void;
    seekTo(s: number): void;
    setVolume(vol: number): void;
    toggleControls(): void;
    getCurrentTime(cb: (s: number) => void): void;
}

type AccelPlayerProps = {
    videoId: string;
    muted?: boolean;
    width?: string | number;
    height?: string | number;
    playing?: boolean;
    title?: boolean;
    volume?: number;
    id?: string;
    className?: string;
    style?: React.CSSProperties;
    startSec?: TimeSpan;
    ui?: {
        enabled?: boolean,
        controls?: boolean;
        pip?: boolean;
        playbackRate?: boolean;
    },
    onReady?: (player: AccelPlayerController) => void;
    onStart?: () => void;
    onStop?: () => void;
    onPlay?: (args: { currentTime: TimeSpan }) => void;
    onPause?: (args: { currentTime: TimeSpan }) => void;
    onSeek?: (args: { currentTime: TimeSpan }) => void;
    onUnmount?: () => void;
}
type PlayerMessageData = {
    source: string;
    videoId: string;
    event?: 'onReady' | 'onStart' | 'onPlay' | 'onPause' | 'onSeek' | 'onStop';
    action?: 'currentTime';
    value?: any;
}

class AccelPlayer extends React.Component<AccelPlayerProps> {

    private siteBaseUrl: string = process.env.NODE_ENV == "development" ? "http://localhost:5005" : "https://v.lpxl.ru";
    private container: any;
    private playerIframe: any;
    private player: AccelPlayerController;
    private ready: boolean = false;
    private disposableStack = new DisposableStack();
    /**
     * callback to return current time after request
     */
    private getCurrentTimeCb: ((s: number) => void) | null = null;

    constructor(props: AccelPlayerProps, ctx: any) {
        super(props, ctx);

        this.refContainer = this.refContainer.bind(this);
    }

    componentDidMount() {
        this.playerIframe = this.createPlayerIframe();
        if (!this.playerIframe) return;

        this.player = {
            mute: () => {
                this.executeAction('mute');
            },
            unmute: () => {
                this.executeAction('unmute');
            },
            play: () => {
                this.executeAction('play');
            },
            pause: () => {
                this.executeAction('pause');
            },
            seekTo: (s: number) => {
                this.executeAction('seekTo', s);
            },
            setVolume: (volume: number) => {
                this.executeAction('setVolume', volume);
            },
            toggleControls: () => {
                this.executeAction('toggleControls');
            },
            getCurrentTime: (cb) => {
                this.getCurrentTimeCb = cb;
                this.executeAction('getCurrentTime');
            }
        };

        const f = this.onMessageReceived.bind(this);
        window.addEventListener('message', f, false);
        this.disposableStack.push(() => window.removeEventListener('message', f));

        this.container.appendChild(this.playerIframe);
        this.disposableStack.push(() => this.container.removeChild(this.playerIframe));
    }

    onMessageReceived(e: MessageEvent<PlayerMessageData>) {
        if (!e.data || e.data.source != 'AccelPlayer' || e.data.videoId != this.props.videoId)
            return;

        switch (e.data.event) {
            case 'onReady': {
                this.ready = true;
                this.props.onReady?.(this.player);
                break;
            }
            case 'onStart': {
                this.props.onStart?.();
                break;
            }
            case 'onPlay': {
                this.props.onPlay?.({
                    currentTime: e.data.value?.currentTime
                        ? TimeSpan.fromSeconds(e.data.value?.currentTime)
                        : TimeSpan.zero
                });
                break;
            }
            case 'onPause': {
                this.props.onPause?.({
                    currentTime: e.data.value?.currentTime
                        ? TimeSpan.fromSeconds(e.data.value?.currentTime)
                        : TimeSpan.zero
                });
                break;
            }
            case 'onSeek': {
                this.props.onSeek?.({
                    currentTime: e.data.value?.currentTime
                        ? TimeSpan.fromSeconds(e.data.value?.currentTime)
                        : TimeSpan.zero
                });
                break;
            }
            case 'onStop': {
                this.props.onStop?.();
                break;
            }
        }

        switch (e.data.action) {
            case 'currentTime': {
                if (this.getCurrentTimeCb) {
                    this.getCurrentTimeCb(e.data.value);
                    this.getCurrentTimeCb = null;
                }
                break;
            }
        }
    }

    executeAction(action: string, value?: any) {
        this.playerIframe?.contentWindow?.postMessage({
            action,
            value
        }, "*");
    }

    componentDidUpdate(prevProps: AccelPlayerProps) {
        const changes = Object.keys(this.props).filter((name) => this.props[name] !== prevProps[name]);
        this.updateProps(changes);
    }

    componentWillUnmount() {
        this.disposableStack.dispose();
        this.props.onUnmount?.();
    }

    private createPlayerIframe() {
        const { videoId, title, playing, startSec, muted, ui } = this.props;

        const videoUrl = new URL(`${this.siteBaseUrl}/v/${videoId}`);
        if (ui?.enabled !== undefined)
            videoUrl.searchParams.append('ui', ui.enabled.toString());
        if (ui?.controls !== undefined)
            videoUrl.searchParams.append('showControls', ui.controls.toString());
        if (title !== undefined)
            videoUrl.searchParams.append('showTitle', title.toString());
        if (playing !== undefined)
            videoUrl.searchParams.append('autoplay', playing.toString());
        if (startSec !== undefined)
            videoUrl.searchParams.append('startSec', startSec.seconds.toString());
        if (muted !== undefined)
            videoUrl.searchParams.append('muted', muted.toString());
        if (ui?.pip !== undefined)
            videoUrl.searchParams.append('pip', ui.pip.toString());
        if (ui?.playbackRate !== undefined)
            videoUrl.searchParams.append('playbackRate', ui.playbackRate.toString());

        const iframe = document.createElement('iframe');
        iframe.src = videoUrl.toString();
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        iframe.style.border = 'none';
        iframe.style.overflow = 'hidden';
        iframe.allow = 'autoplay';
        iframe.allowFullscreen = true;
        iframe.id = `video-${videoId}`;

        return iframe;
    }

    private updateProps(propNames: Array<string>) {
        if (!this.ready) return;
        propNames.forEach(async (name) => {
            const value = this.props[name];
            switch (name) {
                case 'muted':
                    if (value) {
                        this.player.mute();
                    } else {
                        this.player.unmute();
                    }
                    break;
                case 'volume':
                    this.player.setVolume(value);
                    break;
                case 'playing':
                    if (value) {
                        this.player.play();
                    } else if (!value) {
                        this.player.pause();
                    }
                    break;
                case 'id':
                case 'className':
                case 'width':
                case 'height':
                    if (!this.container) return;
                    this.container[name] = value;
                    break;
            }
        });
    }

    private refContainer(container: any) {
        this.container = container;
    }

    render() {
        const { id, className, style, height, width } = this.props;

        return <div
            id={id}
            className={className}
            style={{ width, height, ...style }}
            ref={this.refContainer}>
        </div>;
    }
}

//@ts-ignore
// AccelPlayer.defaultProps = {
//     autoplay: false,
//     controls: true,
//     disableKeyboard: false,
//     allowFullscreen: true,
//     modestBranding: false,
//     playsInline: false,
//     showRelatedVideos: true,
//     showInfo: true,
// };

export default AccelPlayer;
