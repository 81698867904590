import { Updatable } from '../../../..';

export default class NovofonSettings extends Updatable {
    public secret: string;

    constructor(settings?: Partial<NovofonSettings>) {
        super();
        if (settings) this.update(settings);
    }

    update(entity: Partial<NovofonSettings>, allowUndefined = false) {
        super.update(entity, allowUndefined);
    }
}