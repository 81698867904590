import { action, computed, observable } from 'mobx';
import { Disposable } from '../utils';
import Bowser from 'bowser';

export type DeviceSizeType = 'desktop' | 'tablet' | 'mobile';
export type DevicePlatformType = 'desktop' | 'tablet' | 'mobile' | 'tv';
export type DeviceOSType = 'Windows Phone' | 'Windows' | 'macOS' | 'iOS' | 'Android' | 'WebOS' | 'BlackBerry' | 'Bada' | 'Tizen' | 'Linux' | 'Chrome OS' | 'PlayStation 4' | 'Roku';
export type DeviceEngineType = 'EdgeHTML' | 'Blink' | 'Trident' | 'Presto' | 'Gecko' | 'WebKit';
export type DeviceOrientation = 'portrait' | 'landscape';

export default class DeviceStore extends Disposable {
    private browser: Bowser.Parser.ParsedResult;

    @observable width: number = window.innerWidth;
    @observable height: number = window.innerHeight;

    @computed get orientation(): DeviceOrientation {
        return this.height > this.width ? 'portrait' : 'landscape';
    }

    get platform() {
        return this.browser.platform.type as DevicePlatformType;
    }

    get OS(): DeviceOSType {
        return this.browser.os.name as DeviceOSType;
    }

    get engine(): DeviceEngineType {
        return this.browser.engine.name as DeviceEngineType;
    }

    get info(): DeviceEngineType {
        return this.browser.engine.name as DeviceEngineType;
    }

    get isTouch() {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            //@ts-ignore
            (navigator.msMaxTouchPoints > 0));
    }

    get isTelegram() {
        //@ts-ignore
        return window['TelegramWebview'] !== undefined;
    }

    get isInAppBrowser() {
        // facebook, instagram, whatsapp, etc
        return this.isTelegram;
    }

    @computed get size(): DeviceSizeType {
        if (this.width <= 750) return 'mobile';
        else if (this.width <= 1024) return 'tablet';
        return 'desktop';
    }

    @computed get isMobile(): boolean {
        return this.size == 'mobile';
    }

    @computed get isNotDesktop(): boolean {
        return this.size != 'desktop';
    }

    constructor() {
        super();
        this.browser = Bowser.parse(window.navigator.userAgent);

        const func = action(() => {
            this.width = window.innerWidth;
            this.height = window.innerHeight;
        });
        window.addEventListener('resize', func);
        this.toDispose(() => window.removeEventListener('resize', func));
    }
}
