import { action, observable } from 'mobx';
import { CustomField, CustomFieldType } from '../..';

export default class CustomAttribute extends CustomField {
    constructor(attr?: Partial<CustomAttribute>) {
        super(attr);
        if (attr) this.update(attr);
    }

    @observable limitedAccess: boolean = false;

    // hide in view is almost the same as visible field in parent class
    @observable hideInView: boolean = false;

    @action update(attr: Partial<CustomAttribute>, allowUndefined = false) {
        super.update(attr, allowUndefined);
    }

    clone(): CustomAttribute {
        return new CustomAttribute({
            ...this,
            type: this.type ?? CustomFieldType.Text,
            additionalData: this?.additionalData ?? JSON.stringify([])
        });
    }

    static default(){
        return new CustomAttribute({
            type: CustomFieldType.Text,
            additionalData: JSON.stringify([]),
            hideInView: false
        });
    }

    static fromJson(json: any) {
        return new CustomAttribute({
            ...json,
            // set visible to show the 'eye' icon on the list
            visible: !json.hideInView
        });
    }
}