import { Updatable } from '../../Entity';
import { Currency } from '../../../enums';

export default class PaymentGatewayStats extends Updatable {
    constructor(stats?: Partial<PaymentGatewayStats>) {
        super();
        if (stats)
            this.update(stats);
    }

    currency: Currency;
    value: number;

    update(product: Partial<PaymentGatewayStats>) {
        super.update(product);
    }

    clone() {
        return new PaymentGatewayStats({
            ...this
        });
    }

    static fromJson(json: any): PaymentGatewayStats {
        return new PaymentGatewayStats({
            ...json
        });
    }
}
